.legos {
  height: 72px;
  width: 90px;
  margin-left: -48px;
  margin-top: -30px;
  position: relative;
  top: 50%;
  left: 50%;
  cursor: pointer;
}
.legos .lego-top {
  position: absolute;
  left: 25px;
  top: 0px;
  z-index: 4;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  transition-property: transform;
  -webkit-transition-duration: 250ms;
  -moz-transition-duration: 250ms;
  transition-duration: 250ms;
  -webkit-transition-delay: 50ms;
  -moz-transition-delay: 50ms;
  transition-delay: 50ms;
  -webkit-transition-timing-function: ease;
  -moz-transition-timing-function: ease;
  transition-timing-function: ease;
}
.legos .lego-back {
  position: absolute;
  left: 25px;
  top: 20px;
  z-index: 1;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  transition-property: transform;
  -webkit-transition-duration: 250ms;
  -moz-transition-duration: 250ms;
  transition-duration: 250ms;
  -webkit-transition-delay: 25ms;
  -moz-transition-delay: 25ms;
  transition-delay: 25ms;
  -webkit-transition-timing-function: ease;
  -moz-transition-timing-function: ease;
  transition-timing-function: ease;
}
.legos .lego-left {
  position: absolute;
  left: 8px;
  top: 30px;
  z-index: 2;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  transition-property: transform;
  -webkit-transition-duration: 250ms;
  -moz-transition-duration: 250ms;
  transition-duration: 250ms;
  -webkit-transition-delay: 50ms;
  -moz-transition-delay: 50ms;
  transition-delay: 50ms;
  -webkit-transition-timing-function: ease;
  -moz-transition-timing-function: ease;
  transition-timing-function: ease;
}
.legos .lego-right {
  position: absolute;
  left: 42px;
  top: 30px;
  z-index: 2;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  transition-property: transform;
  -webkit-transition-duration: 250ms;
  -moz-transition-duration: 250ms;
  transition-duration: 250ms;
  -webkit-transition-delay: 50ms;
  -moz-transition-delay: 50ms;
  transition-delay: 50ms;
  -webkit-transition-timing-function: ease;
  -moz-transition-timing-function: ease;
  transition-timing-function: ease;
}
.legos.legos-explode .lego-top {
  -webkit-transform: translateY(-15px);
  -moz-transform: translateY(-15px);
  -ms-transform: translateY(-15px);
  -o-transform: translateY(-15px);
  transform: translateY(-15px);
}
.legos.legos-explode .lego-left {
  -webkit-transform: translateX(-10px) translateY(10px);
  -moz-transform: translateX(-10px) translateY(10px);
  -ms-transform: translateX(-10px) translateY(10px);
  -o-transform: translateX(-10px) translateY(10px);
  transform: translateX(-10px) translateY(10px);
}
.legos.legos-explode .lego-right {
  -webkit-transform: translateX(10px) translateY(10px);
  -moz-transform: translateX(10px) translateY(10px);
  -ms-transform: translateX(10px) translateY(10px);
  -o-transform: translateX(10px) translateY(10px);
  transform: translateX(10px) translateY(10px);
}

.lego {
  content: '';
  width: 40px;
  height: 40px;
  position: relative;
}
.lego:after {
  position: absolute;
  display: block;
  content: '';
  width: 20px;
  height: 20px;
  top: 20px;
  left: 10px;
  -webkit-transform: rotateZ(30deg) skewX(30deg) scaleY(0.86) translateX(-10px);
  -moz-transform: rotateZ(30deg) skewX(30deg) scaleY(0.86) translateX(-10px);
  -ms-transform: rotateZ(30deg) skewX(30deg) scaleY(0.86) translateX(-10px);
  -o-transform: rotateZ(30deg) skewX(30deg) scaleY(0.86) translateX(-10px);
  transform: rotateZ(30deg) skewX(30deg) scaleY(0.86) translateX(-10px);
}
.lego:before {
  position: absolute;
  display: block;
  content: '';
  width: 20px;
  height: 20px;
  top: 20px;
  left: 10px;
  -webkit-transform: rotateZ(-30deg) skewX(-30deg) scaleY(0.86) translateX(10px);
  -moz-transform: rotateZ(-30deg) skewX(-30deg) scaleY(0.86) translateX(10px);
  -ms-transform: rotateZ(-30deg) skewX(-30deg) scaleY(0.86) translateX(10px);
  -o-transform: rotateZ(-30deg) skewX(-30deg) scaleY(0.86) translateX(10px);
  transform: rotateZ(-30deg) skewX(-30deg) scaleY(0.86) translateX(10px);
}
.lego .top {
  position: absolute;
  display: block;
  content: '';
  width: 20px;
  height: 20px;
  top: 20px;
  left: 10px;
  -webkit-transform: rotateZ(30deg) skewX(-30deg) scaleY(0.86) translateY(-20px) translateX(-20px);
  -moz-transform: rotateZ(30deg) skewX(-30deg) scaleY(0.86) translateY(-20px) translateX(-20px);
  -ms-transform: rotateZ(30deg) skewX(-30deg) scaleY(0.86) translateY(-20px) translateX(-20px);
  -o-transform: rotateZ(30deg) skewX(-30deg) scaleY(0.86) translateY(-20px) translateX(-20px);
  transform: rotateZ(30deg) skewX(-30deg) scaleY(0.86) translateY(-20px) translateX(-20px);
}
.lego.lego-primary:after {
  background: #ff594a;
}
.lego.lego-primary:before {
  background: #ff4130;
}
.lego.lego-primary .top {
  background: #ff7568;
}
.lego.lego-secondary:after {
  background: #d5d5d5;
}
.lego.lego-secondary:before {
  background: #c9c9c9;
}
.lego.lego-secondary .top {
  background: #e5e5e5;
}

body {
  background: #f8f8f8;
  text-align: center;
}

.tip {
  background: rgba(0, 0, 0, 0.06);
  padding: 5px 15px;
  display: inline-block;
  margin: 15px auto;
  font-family: sans-serif;
  color: #333;
  border-radius: 3px;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
}